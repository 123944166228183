import Fuse from 'fuse.js';
import { debounce } from 'helpers';

export class UiSearchable extends HTMLElement {
  input: HTMLInputElement;
  items: HTMLElement[] = [];

  searchNoResult?: HTMLElement;

  fuse: Fuse<HTMLElement>;

  constructor() {
    super();
    this.input = this.querySelector('input[data-search]');
    if (!this.input) return;

    this.input.addEventListener('input', this.update);
    this.searchNoResult = this.querySelector('[data-search-no-results]');
    this.items = Array.from(this.querySelectorAll<HTMLElement>('[data-searchable-items] > article'));

    this.fuse = new Fuse(this.items, {
      keys: ['innerText']
    });

    this.update();
  }

  update = () => {
    this.setAttribute('loading', '');
    this.search();
  }

  search = debounce(() => {
    const query = this.input.value || '';

    if (query) {
      const results = this.fuse.search(query).map(r => r.item);
      for (const item of this.items) {
        if (results.indexOf(item) >= 0) item.classList.remove('hidden');
        else item.classList.add('hidden');
      }

      if (this.searchNoResult) {
        if (results.length === 0) this.searchNoResult.classList.add('visible');
        else this.searchNoResult.classList.remove('visible');
      }
    } else {
      this.items.forEach(i => i.classList.remove('hidden'));
      if (this.searchNoResult) this.searchNoResult.classList.remove('visible');
    }

    this.removeAttribute('loading');
  })
}
