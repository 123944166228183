import renderHelpers from '../shared/render-helpers';

class CompareMetricsPage {
  constructor(rootSelector) {
    this.$compareMetricsPage = $(rootSelector);

    this.helpers = renderHelpers;

    this.campaignType = this.$compareMetricsPage.find('#select-campaign-type-compare-metrics').val();
    this.campaign = this.$compareMetricsPage.find('#select-campaign-compare-metrics').val();
    this.dateRange = this.$compareMetricsPage.find('#select-date-range-compare-metrics').val();
    this.firstYearRange = this.$compareMetricsPage.find('#select-first-year-range-compare-metrics').val();
    this.secondYearRange = this.$compareMetricsPage.find('#select-second-year-range-compare-metrics').val();
    this.firstMetric = this.$compareMetricsPage.find('#select-first-metric-compare-metrics').val();
    this.secondMetric = this.$compareMetricsPage.find('#select-second-metric-compare-metrics').val();
  }

  formattedLabel(value, param) {
    let fValue;
    if (param === 'ACoS') {
      // percent needs to be in decimal format
      fValue = this.helpers.renderPercent(value / 100);
    } else if (param === 'Total Sales' || param === 'Cost' || param === 'Shipped COGS') {
      // dollar needs to be in cents
      fValue = this.helpers.renderDollarShort(value * 100);
    } else {
      // Shipped Units
      fValue = this.helpers.renderNumberShort(value);
    }
    return fValue;
  }

  formattedValue(value, param) {
    if (value === 0) {
      return 'Data may not be available for this time range';
    }

    let fValue;
    if (param === 'ACoS') {
      // percent needs to be in decimal format
      fValue = this.helpers.renderPercent(value / 100);
    } else if (param === 'Total Sales' || param === 'Cost' || param === 'Shipped COGS') {
      // dollar needs to be in cents
      fValue = this.helpers.renderDollar(value * 100);
    } else {
      // Shipped Units
      fValue = this.helpers.renderNumber(value);
    }
    return fValue;
  }

  getGraphData() {
    const self = this;
    if (self.isFilterValid()) {
      $.ajax({
        url: '/ams/create_graph',
        data: {
          campaign: self.campaign,
          campaign_type: self.campaignType,
          date_range: self.dateRange,
          first_year_range: self.firstYearRange,
          second_year_range: self.secondYearRange,
          first_metric: self.firstMetric,
          second_metric: self.secondMetric,
        },
        success: function (res) {
          const allData = res.data;
          const data = _.map(allData, function (item) {
            return [parseFloat(item['value']), item?.metadata?.first_year_range];
          });
          const data2 = _.map(allData, function (item) {
            return [parseFloat(item['value2']), item?.metadata?.first_year_range];
          });
          const data3 = _.map(allData, function (item) {
            return [parseFloat(item['value3']), item?.metadata?.second_year_range];
          });
          const data4 = _.map(allData, function (item) {
            return [parseFloat(item['value4']), item?.metadata?.second_year_range];
          });
          const key = _.map(allData, function (item) {
            return item['key'];
          });

          const campaignName = $('#compare-metrics-page')
            .find('#select-campaign-compare-metrics :selected')
            .text()
            .trim();

          Highcharts.chart('graph-compare-metrics-page', {
            chart: {
              zoomType: 'xy',
            },
            title: {
              text: campaignName,
            },
            xAxis: {
              categories: key,
            },

            yAxis: [
              {
                gridLineWidth: 0,
                title: {
                  text: self.firstMetric,
                },
                labels: {
                  align: 'left',
                  x: 3,
                  formatter: function () {
                    return self.formattedLabel(this.value, self.firstMetric);
                  },
                },
                min: 0,
              },
              {
                gridLineWidth: 0,
                opposite: true,
                title: {
                  text: self.secondMetric,
                },
                labels: {
                  align: 'right',
                  x: -3,
                  formatter: function () {
                    return self.formattedLabel(this.value, self.secondMetric);
                  },
                },
                min: 0,
              }
            ],

            exporting: {
              chartOptions: {
                plotOptions: {
                  series: {
                    dataLabels: {
                      enabled: true,
                    },
                  },
                },
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  '<b>' +
                  campaignName +
                  '</b><br/>' +
                  this.series.name +
                  '<br/>' +
                  this.x +
                  `${this.point.metadata ? ' (' + this.point.metadata + ')' : ''}` +
                  ': ' +
                  self.formattedValue(this.y, this.series.name)
                );
              },
            },
            series: [
              {
                type: 'line',
                name: `(${self.firstYearRange}) ${self.firstMetric}`,
                data: data,
                keys: ["y", "metadata"], // Mapped to the data array elements
                yAxis: 0,
              },

              {
                type: 'line',
                name: `(${self.firstYearRange}) ${self.secondMetric}`,
                data: data2,
                keys: ["y", "metadata"],
                yAxis: 1,
              },

              {
                type: 'line',
                name: `(${self.secondYearRange}) ${self.firstMetric}`,
                data: data3,
                keys: ["y", "metadata"],
                yAxis: 0,
              },

              {
                type: 'line',
                name: `(${self.secondYearRange}) ${self.secondMetric}`,
                data: data4,
                keys: ["y", "metadata"],
                yAxis: 1,
              },
            ],
          });

          const buttons = [{ extend: 'ilColvis' }];
          if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
            buttons.push({
              extend: 'ilExports',
              title: function () {
                const campaignName = $('#compare-metrics-page')
                  .find('#select-campaign-compare-metrics :selected')
                  .text()
                  .trim();
                return `${self.dateRange} ${self.firstMetric} vs ${self.secondMetric} - ${campaignName} (${self.firstYearRange} vs ${self.secondYearRange})`;
              },
            });
          }

          const dataSet = res.table_data;
          self.dataTable = $('#table-compare-metrics-page').DataTable({
            dom: `
              <'dataTables_header flex items-center justify-end'B>
              <'dataTables_row'tr>
              <'dataTables_footer flex items-center justify-between'lp>
            `,
            buttons: buttons,
            destroy: true,
            data: dataSet,
            pageLength: 55,
            paging: false,
            ordering: false,
            info: false,
            searching: false,
            columns: [
              { title: self.dateRange },
              { title: `${self.firstYearRange} ${self.firstMetric}`, className: 'align-middle' },
              { title: `${self.firstYearRange} ${self.secondMetric}`, className: 'align-middle' },
              { title: `${self.secondYearRange} ${self.firstMetric}`, className: 'align-middle' },
              { title: `${self.secondYearRange} ${self.secondMetric}`, className: 'align-middle' }
            ],
          });
        },
        error: function (err) {
          console.log('error - ', err);
        },
      });
    }
  }

  init() {
    const self = this;
    self.getGraphData();

    self.$compareMetricsPage.find('#select-campaign-type-compare-metrics').on('change', function () {
      self.campaignType = this.value;
      $.ajax({
        url: '/ams/select_campaigns_by_type',
        data: { campaignType: self.campaignType },
        success: function (res) {
          self.campaign = '';
          self.$compareMetricsPage.find('#select-campaign-compare-metrics').html('');

          const activeCampaigns = res.enabled_campaigns;
          const pausedCampaigns = res.paused_campaigns;
          let result = '';
          let i;

          if (activeCampaigns.length > 0 || pausedCampaigns.length > 0) {
            var typeName =
              self.campaignType === 'sponsoredProducts'
                ? 'All sponsored products campaigns'
                : 'All headline search campaigns';
            result += '<option value="all_campaigns" selected>' + typeName + '</option>';
            self.campaign = 'all_campaigns';
          }
          if (activeCampaigns.length > 0) {
            result += '<optgroup label="Active">';
            for (i = 0; i < activeCampaigns.length; i++) {
              result += '<option value="' + activeCampaigns[i][0] + '" >' + activeCampaigns[i][1] + '</option>';
            }
            result += '</optgroup>';
          }
          if (pausedCampaigns.length > 0) {
            result += '<optgroup label="Paused">';
            for (i = 0; i < pausedCampaigns.length; i++) {
              result += '<option value="' + pausedCampaigns[i][0] + '" >' + pausedCampaigns[i][1] + '</option>';
            }
            result += '</optgroup>';
          }
          self.$compareMetricsPage.find('#select-campaign-compare-metrics').append(result);
          self.getGraphData();
        },
      });
      self.getGraphData();
    });
    self.$compareMetricsPage.find('#select-campaign-compare-metrics').on('change', function () {
      self.campaign = this.value;
      self.getGraphData();
    });
    self.$compareMetricsPage.find('#select-date-range-compare-metrics').on('change', function () {
      self.dateRange = this.value;
      self.getGraphData();
    });
    self.$compareMetricsPage.find('#select-first-year-range-compare-metrics').on('change', function () {
      self.firstYearRange = this.value;
      self.getGraphData();
    });
    self.$compareMetricsPage.find('#select-second-year-range-compare-metrics').on('change', function () {
      self.secondYearRange = this.value;
      self.getGraphData();
    });
    self.$compareMetricsPage.find('#select-first-metric-compare-metrics').on('change', function () {
      self.firstMetric = this.value;
      self.getGraphData();
    });
    self.$compareMetricsPage.find('#select-second-metric-compare-metrics').on('change', function () {
      self.secondMetric = this.value;
      self.getGraphData();
    });
  }

  isFilterValid() {
    return this.campaign &&
      this.campaignType &&
      this.dateRange &&
      this.firstYearRange &&
      this.secondYearRange &&
      this.firstMetric &&
      this.secondMetric;
  }
}

export default CompareMetricsPage;
