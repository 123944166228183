import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class ProductsVendor {
  constructor(rootSelector) {
    this.helpers = renderHelpers;
    this.$table = $(rootSelector);
  }

  set_column_name() {
    this.$table = $('#vendor-products-table');
    const wrapper = $('#vendor-products-table_wrapper');
    if (this.$rangeFilter.val() === 'Weekly') {
      wrapper.find('.set-period').text('Week');
    } else if (this.$rangeFilter.val() === 'Monthly') {
      wrapper.find('.set-period').text('Month');
    } else {
      wrapper.find('.set-period').text('Yearly');
    }
  }

  init() {
    const self = this;

    $.urlParam = function (name) {
      var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
      var res = results ? results[1] : '';
      return res;
    };

    // range selector (weekly or monthly)
    this.$rangeFilter = $('#products-range-filter');

    // period selector
    this.$periodFilter = $('#products-period-filter');

    if ($.urlParam('range')) {
      this.$rangeFilter.val($.urlParam('range'));
      this.$rangeFilter[0].dispatchEvent(new Event('change'));
      this.$periodFilter.val($.urlParam('range') + '|' + $.urlParam('beginning_on'));
      this.$periodFilter[0].dispatchEvent(new Event('change'));
    }

    if (!this.$rangeFilter.val()) {
      this.$rangeFilter.val('Monthly');
      this.$rangeFilter[0].dispatchEvent(new Event('change'));
    }

    this.set_column_name();

    var definition = new DataTableDefinition({
      defaultSortByColumns: ['shipped_cogs_cents'],
      defaultSortByDirections: ['desc'],
      columns: [
        {
          name: 'asin',
          data: 'asin',
          className: 'numeric',
          render: self.helpers.renderAsinLink,
        },
        {
          name: 'main_image_thumb',
          data: 'main_image_thumb',
          class: 'w-16 px-1',
          sortable: false,
          render: self.helpers.renderProductThumbnail,
        },
        {
          name: 'asin_name',
          data: function (row, type, val, meta) {
            return (
              row.asin_name || row.amazon_product_title || '<span class="italic text-text/50">No name found</span>'
            );
          },
          className: 'min-w-96',
          render: self.helpers.renderProductName,
        },
        {
          name: 'track_amazon_com',
          data: 'track_amazon_com',
          visible: false,
        },
        {
          name: 'upc',
          data: 'upc',
          className: 'numeric',
        },
        {
          name: 'model_number',
          data: 'model_number',
          className: 'numeric',
        },
        {
          name: 'replenishment_code',
          data: 'replenishment_code',
        },
        {
          name: 'tag_name',
          data: 'tag_name',
          className: 'min-w-48',
          render: self.helpers.renderTag,
        },
        {
          name: 'product_group',
          data: 'product_group',
          visible: false,
        },
        {
          name: 'category',
          data: 'category',
          visible: false,
        },
        {
          name: 'subcategory',
          data: 'subcategory',
          visible: false,
        },
        {
          name: 'last_shipped_cogs_cents',
          data: 'last_shipped_cogs_cents',
          render: self.helpers.renderDollar,
        },
        {
          name: 'shipped_cogs_cents',
          data: 'shipped_cogs_cents',
          render: self.helpers.renderDollar,
        },
        {
          name: 'shipped_cogs_diff_cents',
          data: 'shipped_cogs_diff_cents',
          render: function (data, type, row) {
            if (data) {
              if (data >= 0) {
                return self.helpers.renderDollar(data, true);
              } else {
                return self.helpers.renderDollar(data, false);
              }
            } else {
              return '-';
            }
          },
        },
        {
          name: 'shipped_cogs_change',
          data: 'shipped_cogs_change',
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'last_shipped_units',
          data: 'last_shipped_units',
          render: self.helpers.renderNumber,
          visible: false,
        },
        {
          name: 'shipped_units',
          data: 'shipped_units',
          render: self.helpers.renderNumber, //,
          // visible: false
        },
        {
          name: 'shipped_units_diff',
          data: 'shipped_units_diff',
          visible: false,
          render: self.helpers.renderMetricChangeNumber,
        },
        {
          name: 'shipped_units_change',
          data: 'shipped_units_change',
          visible: false,
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'shipped_revenue_cents',
          data: 'shipped_revenue_cents',
          visible: true,
          render: self.helpers.renderDollar,
        },
        {
          name: 'shipped_revenue_prior_period',
          data: 'shipped_revenue_prior_period',
          visible: false,
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'shipped_revenue_same_period_last_year',
          data: 'shipped_revenue_same_period_last_year',
          visible: false,
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'customer_returns',
          data: 'customer_returns',
          visible: false,
          render: self.helpers.renderNumber,
        },
        {
          name: 'sellable_on_hand_units',
          data: 'sellable_on_hand_units',
          render: self.helpers.renderNumber,
          defaultContent: 0,
        },
        {
          name: 'sellable_on_hand_inventory_cents',
          data: 'sellable_on_hand_inventory_cents',
          render: self.helpers.renderDollar,
          visible: false,
        },
        {
          name: 'open_purchase_order_quantity',
          data: 'open_purchase_order_quantity',
          render: self.helpers.renderNumber,
          visible: false,
          defaultContent: 0,
        },
        {
          name: 'net_received_cents',
          data: 'net_received_cents',
          render: self.helpers.renderDollar,
        },
        {
          name: 'net_received_units',
          data: 'net_received_units',
          render: self.helpers.renderNumber,
        },
        {
          name: 'average_sale_price_cents',
          data: 'average_sale_price_cents',
          render: self.helpers.renderDollar,
        },
        {
          name: 'average_sale_price_prior_period',
          data: 'average_sale_price_prior_period',
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'glance_views',
          data: 'glance_views',
          render: self.helpers.renderNumber,
          defaultContent: 0,
        },
        {
          name: 'glance_view_prior_period',
          data: 'glance_view_prior_period',
          visible: false,
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'glance_view_last_year',
          data: 'glance_view_last_year',
          visible: false,
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'conversion_rate',
          data: 'conversion_rate',
          visible: true,
          render: function (data, type, row) {
            if (parseFloat(data) >= 0) {
              return self.helpers.renderPercent(data);
            } else {
              return '-';
            }
          },
          defaultContent: 0,
        },
        {
          name: 'conversion_rate_prior_period',
          data: 'conversion_rate_prior_period',
          visible: false,
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'conversion_rate_last_year',
          data: 'conversion_rate_last_year',
          visible: false,
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'net_ppm',
          data: 'net_ppm',
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'net_ppm_prior_period',
          data: 'net_ppm_prior_period',
          visible: false,
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'net_ppm_last_year',
          data: 'net_ppm_last_year',
          visible: false,
          render: self.helpers.renderMetricChangePercent,
        },
        {
          name: 'token',
          data: 'token',
          visible: false,
          render: self.helpers.renderProductLink,
        },
      ],
    });

    // if query string has replenishment_codes, then set the order of column 10 and 8, to
    // asc and desc.
    function set_order() {
      if ($.urlParam('replenishment_codes')) {
        return [
          [definition.columnIndex('sellable_on_hand_units'), 'asc'],
          [definition.columnIndex('shipped_units'), 'desc'],
        ];
      } else {
        return definition.order();
      }
    }

    const buttons = [{
      extend: 'ilColvis',
      columns: definition.columnIndexes([
        'asin',
        'asin_name',
        'track_amazon_com',
        'upc',
        'model_number',
        'replenishment_code',
        'tag_name',
        'product_group',
        'category',
        'subcategory',
        'last_shipped_cogs_cents',
        'shipped_cogs_cents',
        'shipped_cogs_diff_cents',
        'shipped_cogs_change',
        'last_shipped_units',
        'shipped_units',
        'shipped_units_diff',
        'shipped_units_change',
        'shipped_revenue_cents',
        'shipped_revenue_prior_period',
        'shipped_revenue_same_period_last_year',
        'customer_returns',
        'sellable_on_hand_units',
        'sellable_on_hand_inventory_cents',
        'open_purchase_order_quantity',
        'net_received_cents',
        'net_received_units',
        // 'weeks_of_coverage',
        // 'weeks_of_coverage_with_po',
        'average_sale_price_cents',
        'average_sale_price_prior_period',
        'shipped_units',
        'shipped_units_prior_period',
        'glance_views',
        'glance_view_prior_period',
        'glance_view_last_year',
        'conversion_rate',
        'conversion_rate_prior_period',
        'conversion_rate_last_year',
        'net_ppm',
        'net_ppm_prior_period',
        'net_ppm_last_year',
        'token',
      ]),
    }];

    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilCsvServer',
        url: TORO.shared.urlHostPathname().replace('/vendor', '') + '/datatables.csv',
      });
    }

    var table = this.$table.DataTable({
      serverSide: true,
      processing: true,
      info: true,
      ajax: {
        data: (d) => {
          // Send the values Range and Period to the backend.
          // In the form of "Weekly|2018-06-03",
          d['range_period'] = self.$periodFilter.val();
        },
        url: TORO.shared.urlHostPathname().replace('/vendor', '') + '/datatables',
        type: 'POST',
        error: function (xhr, error, thrown) {
          // Handle requests that are being cancelled because of refresh / unloading
          // https://stackoverflow.com/questions/699941/handle-ajax-error-when-a-user-clicks-refresh/18170879#18170879
          // Various solutions. For now just ignore, since its typically a false
          // positive right now...
        },
      },
      paging: true,
      pageLength: 25,
      dom: "<'dataTables_header border-b-0'fB><'dataTables_body border-b border-border'i><'dataTables_body'tr><'dataTables_footer'p>",
      bAutoWidth: false,
      deferRender: true,
      language: {
        ...TORO.shared.dataTableDefaults.language('products'),
        info: 'Showing _START_ to _END_ of _TOTAL_ products this period',
        infoEmpty: 'No products found for this period',
        emptyTable: 'No products found for this period',
      },
      buttons: buttons,
      order: set_order(),
      columns: definition.columns,
    });

    // Code for the replenishment filter at the top of the page.
    var $replenishmentFilter = $('#products-replenishment-filter');

    $replenishmentFilter.on('change', function (e) {
      var targets = [];
      $replenishmentFilter.find('option:selected').each(function () {
        targets.push($(this).val());
      });
      var regex = _.map(targets, function (t) {
        return TORO.shared.escapeRegExp(t);
      }).join('|');
      table.column(definition.columnIndex('replenishment_code')).search(regex, true, false).draw();
      // table.search(regex, true, false).draw();
    });

    var replenishmentCodes = TORO.shared.getParameterByName('replenishment_codes');
    if (replenishmentCodes) {
      // set initial table filtering from query param
      $replenishmentFilter.val(replenishmentCodes.split(','));
      $replenishmentFilter[0].dispatchEvent(new Event('change'));
    }

    // Clear the "period" select dropdown
    function emptyPeriodOptions() {
      self.$periodFilter.empty();
    }

    // Get "period" options for the server and load them into the select dropdown
    function getPeriodOptions(range, callback) {
      $.get('report_' + range, (data) => {
        var options = data.data;

        _.each(options, (item) => {
          var text = item.split('|')[0];
          var value = item.split('|')[1];
          var month_or_week = self.$rangeFilter.val();
          self.$periodFilter.append("<option value='" + month_or_week + '|' + value + "'>" + text + '</option>');
        });

        callback(options);
      });
    }

    // When we first load the "range" dropdown, load the period dropdown and set
    // the select item based off the query param.
    emptyPeriodOptions();
    getPeriodOptions(self.$rangeFilter.val().toLowerCase(), function (options) {
      // If there was a range passed in via the URL, set the range and beginning_on value
      // Otherwise default the first value for period
      if ($.urlParam('range')) {
        self.$periodFilter.val($.urlParam('range') + '|' + $.urlParam('beginning_on'));
      } else if (options[0]) {
        self.$periodFilter.val(self.$rangeFilter.val() + '|' + options[0].split('|')[1]);
      }

      self.$periodFilter[0].dispatchEvent(new Event('change'));
      self.set_column_name();
    });

    // On "range" change, get the new period dropdown
    self.$rangeFilter.on('change', function () {
      emptyPeriodOptions();
      getPeriodOptions(this.value.toLowerCase(), function (options) {
        // if there is an option, default to the first one.
        if (options[0]) {
          self.$periodFilter.val(self.$rangeFilter.val() + '|' + options[0].split('|')[1]);
          self.$periodFilter[0].dispatchEvent(new Event('change'));
        }
        self.set_column_name();
      });
    });

    // ok when the "Show Column" button is clicked, check if we are weekly / monthly
    // and change the text in the dropdown.
    // I wonder if there is an alternative to doing it via nth-child...
    $('#vendor-products-table_wrapper')
      .find('.buttons-collection.buttons-colvis')
      .click(function () {
        var $dropdown = $('.dt-button-collection');
        var cogsIndex = definition.columnIndex('last_shipped_cogs_cents');
        var unitsIndex = definition.columnIndex('last_shipped_units');
        if (self.$rangeFilter.val() === 'Weekly') {
          $dropdown
            .find('.dt-button:nth-child(' + cogsIndex + ')')
            .find('span')
            .text('Last Week Shipped COGS');
          $dropdown
            .find('.dt-button:nth-child(' + unitsIndex + ')')
            .find('span')
            .text('Last Week Shipped Units');
        } else if (self.$rangeFilter.val() === 'Monthly') {
          $dropdown
            .find('.dt-button:nth-child(' + cogsIndex + ')')
            .find('span')
            .text('Last Month Shipped COGS');
          $dropdown
            .find('.dt-button:nth-child(' + unitsIndex + ')')
            .find('span')
            .text('Last Month Shipped Units');
        } else {
          // Yearly
          $dropdown
            .find('.dt-button:nth-child(' + cogsIndex + ')')
            .find('span')
            .text('Last Year Shipped COGS');
          $dropdown
            .find('.dt-button:nth-child(' + unitsIndex + ')')
            .find('span')
            .text('Last Year Shipped Units');
        }
      });

    // When any link is clicked, self.set_column_name();
    // Not 100% sure why this is necessary.
    $('body').click(function (e) {
      if (e.target.tagName === 'A') self.set_column_name();
    });

    // when the period selector is changed, refresh the table.
    self.$periodFilter.on('change', function (e) {
      if (this.value) {
        table.draw();
      }
    });
  }
}

export default ProductsVendor;
