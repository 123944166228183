import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';
import BuyBoxGraph from '../shared/buy_box_graph';

class ProductBuyBoxes {
  constructor(rootSelector) {
    this.$table = $(rootSelector);
    this.ajaxUrl = this.$table.attr('data-ajax-url');
    this.beginningOn = this.$table.attr('data-beginning-on');
    this.helpers = renderHelpers;
  }

  initDatePicker() {
    this.datePicker = $('#product-buy-boxes-date')
      .daterangepicker(
        {
          showWeekNumbers: true,
          startDate: moment().subtract(90, 'days'),
          endDate: moment(),
          opens: 'left',
          ranges: {
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Two Weeks Ago': [
              moment().subtract(2, 'weeks').startOf('week'),
              moment().subtract(2, 'weeks').endOf('week'),
            ],
            'Two Months Ago': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(2, 'months').endOf('month'),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          maxDate: moment(),
        },
        (start, end, label) => {
          this.dataTable.ajax.reload((json) => {
            this.initGraph(json);
          });
        },
      )
      .data('daterangepicker');
  }

  defineTable() {
    this.tableDefinition = new DataTableDefinition({
      defaultSortByColumns: ['crawled_at'],
      defaultSortByDirections: ['desc'],
      columns: [
        {
          name: 'crawled_at',
          data: 'crawled_at',
          render: this.helpers.renderDate,
        },
        {
          name: 'status',
          data: 'status',
        },
        {
          name: 'seller_name',
          data: 'seller_name',
          render: (data, type, row, meta) => {
            return data || '-';
          },
        },
        {
          name: 'seller_id',
          data: 'seller_aid',
        },
        {
          name: 'price_cents',
          data: 'price_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'availability',
          data: 'availability',
        },
        {
          name: 'amazon_price_cents',
          data: 'amazon_price_cents',
          render: (data, type, row, meta) => {
            return this.helpers.renderDollar(data);
          },
        },
        {
          name: 'amazon_fast_track_message',
          data: 'amazon_fast_track_message',
          render: (data, type, row, meta) => {
            return data || '-';
          },
        },
      ],
    });
  }

  initGraph(json) {
    $('#product-buy-box-graph').attr({
      'data-buy-boxes': json['buy_box'],
      'data-min-price-cents': json['min_price_cents'],
      'data-max-price-cents': json['max_price_cents'],
    });
    const initIfExist = function (klass, selector) {
      if ($(selector).length > 0) {
        new klass(selector).init();
      }
    };

    initIfExist(BuyBoxGraph, '#product-buy-box-graph');
  }

  initTable() {
    const buttons = [{ extend: 'ilColvis' }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: this.filename,
        index: 1,
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 10,
      ajax: {
        url: this.ajaxUrl,
        data: (d) => {
          d.beginning_on = this.datePicker.startDate.format('YYYY-MM-DD');
          d.ending_on = this.datePicker.endDate.format('YYYY-MM-DD');
        },
        dataSrc: 'data',
      },
      columnDefs: [
        {
          defaultContent: '-',
          targets: '_all',
        },
      ],
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('buy boxes'),
      buttons: buttons,
      order: this.tableDefinition.order(),
      columns: this.tableDefinition.columns,
    });

    this.dataTable.on('xhr.dt', (e, settings, json) => {
      this.initGraph(json);
    });
  }

  init() {
    this.initDatePicker();
    this.defineTable();
    this.initTable();
  }
}

export default ProductBuyBoxes;
