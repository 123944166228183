import renderHelpers from '../shared/render-helpers';

class BuyBox {
  constructor(rootSelector) {
    this.$graph = $(rootSelector);
    this.$table = $('#product-buy-box-table');

    this.helpers = renderHelpers;
    this.buyBoxData = JSON.parse(this.$graph.attr('data-buy-boxes'));
    this.minPriceCents = JSON.parse(this.$graph.attr('data-min-price-cents'));
    this.maxPriceCents = JSON.parse(this.$graph.attr('data-max-price-cents'));

    this.normalColor = '#0D8ECF';
    this.highColor = '#FCD202';
    this.criticalColor = '#FF6600';
    this.unknownColor = '#D6D6D6';
    this.lineColor = '#D6D6D6';

    this.buyBoxData.sort(function (a, b) {
      if (a.crawled_at < b.crawled_at) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  init() {
    this.drawDailyGraph();
  }

  // returns an object like
  // { 2018-1-6: {}, 2018-1-13: {}} if for weeks or
  // { 2018-1-1: {}, 2018-2-1:{}} if for months
  hashWithDateAsKeys(period) {
    const periodRollup = {};
    if (this.buyBoxData.length > 0) {
      const firstPeriod = moment(this.buyBoxData[0].crawled_at).startOf(period);
      const lastPeriod = moment(this.buyBoxData[this.buyBoxData.length - 1].crawled_at).startOf(period);

      let currentPeriod = firstPeriod;
      while (currentPeriod <= lastPeriod) {
        periodRollup[currentPeriod.format('YYYY-MM-DD')] = {};
        currentPeriod = firstPeriod.add(1, period);
      }
    }

    return periodRollup;
  }

  drawDailyGraph() {
    const self = this;
    const normal = [];
    const unknown = [];
    const high = [];
    const critical = [];
    const seriesData = _.map(this.buyBoxData, (item) => {
      item.y = item.price_cents;
      item.x = Date.parse(item.crawled_at);
      // item.color = this.getFillColor(item.status);

      switch (item.status) {
        case '4 - Buy Box Won':
          normal.push(item);
          break;
        case '3 - Unknown':
          unknown.push(item);
          break;
        case '2 - Buy Box at Risk':
          high.push(item);
          break;
        case '1 - Buy Box Lost':
          critical.push(item);
          break;
      }
      return item;
    });

    const plotLines = [];
    if (this.minPriceCents) {
      plotLines.push({
        value: this.minPriceCents,
        color: '#333333',
        dashStyle: 'dash',
        width: 1,
        zIndex: 5,
        label: {
          text: `Min: ${this.helpers.renderDollar(this.minPriceCents)}`,
          y: 12,
        },
      });
    }
    if (this.maxPriceCents) {
      plotLines.push({
        value: this.maxPriceCents,
        color: '#333333',
        dashStyle: 'dash',
        width: 1,
        zIndex: 5,
        label: {
          text: `Max: ${this.helpers.renderDollar(this.maxPriceCents)}`,
        },
      });
    }

    Highcharts.chart('product-buy-box-graph', {
      title: {
        text: 'Buy Box Price',
      },
      chart: {
        type: 'scatter',
        zoomType: 'x',
      },
      xAxis: {
        type: 'datetime',
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        title: {
          enabled: true,
          text: 'Date',
        },
      },
      yAxis: {
        title: {
          text: 'Buy Box Price',
        },
        labels: {
          formatter: function () {
            return self.helpers.renderDollar(this.value);
          },
        },
        minRange: this.maxPriceCents,
        maxRange: this.minPriceCents,
        plotLines: plotLines,
      },
      tooltip: {
        formatter: function () {
          let buybox = `
<b>${Highcharts.dateFormat('%b %e, %Y', new Date(this.x))}</b><br/>
<b>Price: ${self.helpers.renderDollar(this.y)}</b><br/>
Status: ${this.point.status || '(None)'}<br/>
Seller: ${this.point.seller_text || '(None)'}<br/>
Availability: ${this.point.availability || '(None)'}<br/>`;

          if (this.point.status === '1 - Buy Box Lost') {
            buybox += `
<b>Amazon Offer:</b><br/>
Price: ${self.helpers.renderDollar(this.point.amazon_price_cents)}</b><br/>
Fast Track Message: ${this.point.amazon_fast_track_message || '(None)'}<br/>`;
          }
          return buybox;
        },
      },
      series: [
        {
          name: 'Buy Box Price',
          data: seriesData,
          step: 'left',
          color: this.lineColor,
          enableMouseTracking: false,
          showInLegend: false,
          marker: {
            enabled: false,
          },
        },
        {
          name: '4 - Buy Box Won',
          data: normal,
          step: 'left',
          color: this.normalColor,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 4,
          },
        },
        {
          name: '3 - Unknown',
          data: unknown,
          step: 'left',
          color: this.unknownColor,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 4,
          },
        },
        {
          name: '2 - Buy Box at Risk',
          data: high,
          step: 'left',
          color: this.highColor,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 4,
          },
        },
        {
          name: '1 - Buy Box Lost',
          data: critical,
          step: 'left',
          color: this.criticalColor,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 4,
          },
        },
      ],
    });
  }

  getFillColor(status) {
    switch (status) {
      case '4 - Buy Box Won':
        return this.normalColor;
      case '3 - Unknown':
        return this.unknownColor;
      case '2 - Buy Box at Risk':
        return this.highColor;
      case '1 - Buy Box Lost':
        return this.criticalColor;
    }
  }
}

export default BuyBox;
