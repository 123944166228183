import { sleep } from 'helpers';
import type { Accordion } from './accordion';

export class Sidebar {
  root: HTMLElement;
  accordion: Accordion;
  mobileMenuDisabled = false;

  constructor(root: HTMLElement) {
    this.root = root;
  }

  init = async () => {
    if (!this.root?.dataset.accordion) return;

    // wait for the accordion instance to get registered
    while (!this.accordion) {
      this.accordion = window.UI.accordion?.instances.find((x) => x.id === this.root.dataset.accordion);
      await sleep(50);
    }

    // hide Explore Data accordion when there are no visible entries
    const contentRoot = this.root.querySelector('[data-accordion-content=subnav-explore-data]');
    if (contentRoot && contentRoot.children.length === 0) {
      const subnavGroup = contentRoot.closest('.subnav-group');
      if (subnavGroup) subnavGroup.parentElement.removeChild(subnavGroup);
    }

    // map events
    window.addEventListener('resize', this.updateMobileMenuDisabledState);
    this.updateMobileMenuDisabledState();
  };

  updateMobileMenuDisabledState = async () => {
    this.mobileMenuDisabled = window.innerWidth >= 1024;
    if (this.mobileMenuDisabled && !this.root.hasAttribute('data-accordion-disabled')) {
      this.accordion.open();
      this.root.dataset.accordionDisabled = '';
    } else if (!this.mobileMenuDisabled && this.root.hasAttribute('data-accordion-disabled')) {
      delete this.root.dataset.accordionDisabled;
      this.accordion.close();
    }
  };
}

export const init = () => {
  document.querySelectorAll<HTMLElement>('[data-sidebar]').forEach(el => {
    const root = el.closest<HTMLElement>('[data-accordion]');
    if (!root) return;
    const sidebar = new Sidebar(root);
    sidebar.init();
  });
};

export default {
  init,
};
